import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StyledFooter from './styles/Footer';

const Footer = () => (
  <StyledFooter className="footer bg-white shadow align-self-end py-3 px-xl-5 w-100">
    <div className="container-fluid">
      <Row>
        <Col md="6" className="text-center text-md-left">
          <p>BITSA © 2020</p>
        </Col>
      </Row>
    </div>
  </StyledFooter>
);

export default Footer;
