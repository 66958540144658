import styled from 'styled-components';

export default styled.footer`
  margin-top: 10rem;

  p {
    margin-bottom: 0;
    font-weight: 600;
    letter-spacing: 0.1em;
    color: var(--dark);
  }
  @media (min-width: 2560px) {
    margin-top: 16rem;
  }
  @media (max-width: 1440px) {
    margin-top: 7rem;
  }
  @media (max-width: 1024px) {
    margin-top: 10rem;
  }
  @media (max-width: 768px) {
    margin-top: 10rem;
  }
  @media (max-width: 425px) {
    margin-top: 8rem;
  }
`;
