import styled from 'styled-components';

export default styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  min-height: calc(100vh - 72px);
  background: url(/img/skyline.svg) repeat-x bottom center;
  background-size: 1024px;
`;
