import styled from 'styled-components';

export default styled.div`
  .navbar-nav {
    flex-direction: row;
  }
  .dropdown-menu {
    right: 0;
    top: 101%;
    left: auto;
    border: none;
    min-width: 10rem;
    padding: 0.5rem 0;
    font-size: 0.9rem;
    position: absolute;
    margin: 0.125rem 0 0;
    border-radius: 0.3rem;
    background-color: var(--white);
    border: 1px solid transparent;
    box-shadow: 0 0.125rem 0.8rem var(--shadow);
  }
  .dropdown-item {
    color: var(--black-two);
    font-weight: 400;
    padding: 0.8rem 1.5rem;
    background-color: var(--white);
  }
  .dropdown-item:hover {
    background-color: var(--gray-hover);
  }
  .dropdown-item:active {
    color: var(--white);
    background-color: var(--dark-sky-blue-two);
  }
  .dropdown-toggle::after {
    display: none;
  }
  .sidebar-toggler {
    cursor: pointer;
    font-weight: 300;
    font-size: 1.125rem;
    transition: color 0.3s;
  }
  .logo {
    padding-top: 0;
  }
  .logo.error {
    margin-left: 4rem;
  }
  .avatar {
    width: 40px;
    max-width: 2.5rem;
  }
  .name {
    letter-spacing: 0;
  }
`;
