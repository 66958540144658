import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import BootstrapNavbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { screens } from '@/constants';
import useGlobalContext from '@/hooks/useGlobalContext';
import { useTranslation } from '@/i18n';
import StyledNavbar from './styles/Navbar';

const Navbar = ({ error, accountMe }) => {
  const [account, setAccount] = useState();
  const { t } = useTranslation();
  const { toggleShrink, navigateTo, logOut } = useGlobalContext();

  useEffect(() => {
    if (accountMe) {
      setAccount(accountMe.account);
    }
  }, [accountMe]);

  const handleSelect = option => navigateTo(option);

  return (
    <StyledNavbar>
      <BootstrapNavbar expand="lg" sticky="top" className="px-4 py-2 bg-white shadow">
        {!error && (
          <BootstrapNavbar.Brand className="sidebar-toggler text-gray-500 mr-4 mr-lg-5">
            <i aria-hidden="true" className="fas fa-align-left" onClick={toggleShrink} />
          </BootstrapNavbar.Brand>
        )}
        <BootstrapNavbar.Brand as="div" className={`logo${(error && ' error') || ''}`}>
          <img src="/img/logo-navbar.svg" alt="logo-navbar" />
        </BootstrapNavbar.Brand>
        {!error && (
          <Nav className="ml-auto align-items-center mb-0">
            <NavDropdown
              className="ml-auto"
              title={<img src="/img/avatar.svg" alt="avatar" className="avatar rounded-circle shadow" />}
            >
              <NavDropdown.Item eventKey={screens.DASHBOARD} onSelect={handleSelect}>
                <strong className="name d-block text-uppercase">
                  {(account && (account.name ? `${account.name} ${account.surname}` : account.email)) || '-'}
                </strong>
              </NavDropdown.Item>
              <NavDropdown.Item eventKey={screens.PLANS} onSelect={handleSelect}>
                {t('menu.plans')}
              </NavDropdown.Item>
              <NavDropdown.Item eventKey={screens.POINTS} onSelect={handleSelect}>
                {t('menu.points')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item eventKey={screens.SETTINGS} onSelect={handleSelect}>
                {t('menu.settings')}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={logOut}>{t('menu.logOut')}</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        )}
      </BootstrapNavbar>
    </StyledNavbar>
  );
};

Navbar.defaultProps = {
  error: false,
  accountMe: null,
};

Navbar.propTypes = {
  error: PropTypes.bool,
  accountMe: PropTypes.shape({
    account: PropTypes.shape({}).isRequired,
  }),
};

export default Navbar;
